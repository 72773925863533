<template>
	<p class="mt-5">{{ this.$root?.translate('email-already-in-system') }}</p>
	<p class="mb-4">{{ this.$root?.translate('enter-your-password') }}</p>

	<form @submit.prevent="this.checkValidity()">
		<div class="row">
			<div class="col-12">
				<password-unit
						v-model="this.modelValue.password"
						ref="registrationPasswordInputUnit"
						:options="{
							slug: 'registration-password',
							name: this.$root?.translate('password'),
							placeholder: this.$root?.translate('min-8-char'),
							minlength: 8,
							required: true,
							errorState: this.errors.password.invalid,
							infoText: this.errors.password.infoText,
							autofocus: true,
						}"
				/>
			</div>
		</div>

		<a class="mt-2 mb-5 login-registration__lost-password-link" @click="this.$emit('showLostPassword')">
			{{ this.$root?.translate('forget-password') }}
		</a>

		<div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between">
			<button-unit
					:options="{
						text: this.$root?.translate('back'),
						shape: ButtonUnitShape.ROUNDED,
						textColor: this.primaryColorVar,
						bgColor: '',
						class: 'lg bordered-lg login-registration__secondary-btn',
						disabled: this.isLoading,
					}"
					@click-on-btn="this.goBack()"
			/>
			<button-unit
					:options="{
						text: this.$root?.translate('general-login'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
			/>
		</div>
	</form>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import {RegistrationModelInterface} from "../../../Interfaces/RegistrationModelInterface";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import SweetAlertMixin from "../../../Mixins/SweetAlertMixin";
import PasswordUnit from "../../Units/PasswordUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import InputUnit from "../../Units/InputUnit.vue";

export default defineComponent({
	name: "LoginRegistrationPassword",
	components: {InputUnit, ButtonUnit, PasswordUnit},
	mixins: [ProfilePageMixin, SweetAlertMixin],
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
	},
	emits: ['showLostPassword', 'goBack', 'closeModal'],
	setup() {
		return {
			authStore: useAuthStore(),
		}
	},
	data() {
		return {
			isLoading: false,
			errors: {
				password: {
					invalid: false,
					infoText: '',
				}
			}
		}
	},
	methods: {
		reset(): void {
			this.errors.password.invalid = false;
			this.errors.password.infoText = '';
		},
		goBack(): void {
			this.reset();
			this.modelValue.password = '';
			this.$emit('goBack');
		},
		checkValidity(): void {
			this.reset();

			if (this.errors.password.invalid) {
				// @ts-ignore
				this.errors.password.infoText = this.$refs.registrationPasswordInputUnit.$refs['registration-password'].validationMessage;
			} else {
				this.recaptcha();
			}
		},
		async recaptcha() {
			if (this.modelValue?.email?.length === 0 || this.modelValue?.password?.length === 0) {
				return;
			}
			//@ts-ignore
			await this.$recaptchaLoaded();
			//@ts-ignore
			const token = await this.$recaptcha('login');
			this.login(token);
		},
		login(token: string): void {
			this.isLoading = true;
			this.authStore.login(this.modelValue.email, this.modelValue.password, token).then(() => {
				this.reset();
				this.$emit('closeModal');
				this.authStore.getMe();
				this.$router.push({name: 'profile'});
			}).catch((error) => {
				this.customErrorAlert(this.$root?.translate('alert-error-title'), error.response?.data?.errors?.email);
			}).finally(() => this.isLoading = false);
		},
	},
});
</script>

<style scoped>
</style>

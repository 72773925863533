<template>
	<div class="profile-sidebar__collapse gap-4 gap-lg-3">
		<template v-for="nav in this.navigations">
			<collapse-item v-if="!nav.hasOwnProperty('isEnabled') || nav.hasOwnProperty('isEnabled') && nav.isEnabled === true" :options="nav" :textColor="this.textColor"/>
		</template>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import CollapseItem from "../../../Components/CollapseItem.vue";
import {CollapseItemInterface} from "../../../Components/Interfaces/CollapseItemInterface";
import {useSiteCheckStore} from "../../../Stores/SiteCheck/SiteCheck";
import {SiteCheckInterface} from "../../../Stores/SiteCheck/Interfaces/SiteCheckInterface";
import {useLanguagesStore} from "@WebApp/Stores/LanguagesStore";

export default defineComponent({
	name: "ProfileSidebarLinks",
	components: {CollapseItem},
	props: {
		isItOpenByDefault: {
			type: Boolean,
			required: true,
		},
		textColor: {
			type: String,
			default: '',
			required: false,
		},
	},
	setup() {
		return {
			siteStore: useSiteCheckStore(),
			languageStore: useLanguagesStore()
		}
	},
	data() {
		return {
			navigations: [] as CollapseItemInterface[],
		}
	},
	mounted() {
		this.getNavigations();
	},
	computed: {
		profileConfig(): SiteCheckInterface {
			return this.siteStore.currentSite?.profile?.config;
		},
		currentSite(): SiteCheckInterface {
			return this.siteStore.currentSite;
		},
		textColorVar(): string {
			if (this.textColor?.length) {
				return `var(--${this.textColor})`;
			} else {
				return '';
			}
		},
		language(): string {
			const currentLang = this.languageStore.currentLang;
			const defaultLang = this.languageStore.defaultLang;
			if (this.pathTranslations.hasOwnProperty(currentLang)) {
				return currentLang;
			}
			return defaultLang;
		}
	},
	methods: {
		getNavigations(): void {
			this.navigations = [
				{
					icon: 'fa-regular fa-user',
					title: this.translate('profile-sidebar-profile-settings'),
					isItOpenByDefault: this.isItOpenByDefault,
					children: [
						{
							title: this.translate('profile-sidebar-personal'),
							link: this.pathTranslations[this.language]['profile-personal'],
							isEnabled: true,
						},
						{
							title: this.translate('profile-sidebar-notifications'),
							link: this.pathTranslations[this.language]['profile-notifications'],
							isEnabled: true,
						},
						{
							title: this.$root?.translate('profile-sidebar-password'),
							link: this.pathTranslations[this.language]['profile-password'],
							isEnabled: true,
						},
					],
				},
				{
					icon: 'fa-regular fa-file-lines',
					title: this.translate('profile-sidebar-study'),
					isItOpenByDefault: this.isItOpenByDefault,
					isEnabled: this.profileConfig?.student_button,
					children: [
						{
							title: this.translate('profile-sidebar-student-data'),
							link: this.pathTranslations[this.language]['profile-student-data'],
							isEnabled: this.profileConfig?.student_data_button,
						},
						{
							title: this.translate('profile-sidebar-student-cooperative'),
							link: this.pathTranslations[this.language]['profile-student-cooperative'],
							isEnabled: this.profileConfig?.student_cooperative_button,
						},
					],
				},
				{
					icon: 'fa-solid fa-chart-line',
					title: this.translate('profile-sidebar-career'),
					isItOpenByDefault: this.isItOpenByDefault,
					isEnabled: (this.currentSite.default_language !== 'sr' && this.profileConfig?.education_button === true) || this.profileConfig?.biography_button === true,
					children: [
						{
							title: this.$root?.translate('profile-sidebar-education'),
							link: this.pathTranslations[this.language]['profile-education'],
							isEnabled: this.currentSite.default_language !== 'sr' && this.profileConfig?.education_button === true,
						},
						{
							title: this.$root?.translate('profile-sidebar-biography'),
							link: this.pathTranslations[this.language]['profile-biography'],
							isEnabled: this.profileConfig?.biography_button === true,
						},
					],
				},
				{
					icon: 'fa-solid fa-arrow-pointer',
					title: this.translate('profile-sidebar-activity'),
					isItOpenByDefault: this.isItOpenByDefault,
					isEnabled: this.profileConfig?.saved_adverts_button === true || this.profileConfig?.saved_searches_button === true || this.profileConfig?.signed_up_adverts_button === true,
					children: [
						{
							title: this.translate('profile-sidebar-saved-adverts'),
							link: this.pathTranslations[this.language]['profile-saved-adverts'],
							isEnabled: this.profileConfig?.saved_adverts_button === true,
						},
						{
							title: this.translate('profile-sidebar-saved-searches'),
							link: this.pathTranslations[this.language]['profile-saved-searches'],
							isEnabled: this.profileConfig?.saved_searches_button === true,
						},
						{
							title: this.translate('profile-sidebar-signed-up-adverts'),
							link: this.pathTranslations[this.language]['profile-signed-up-adverts'],
							isEnabled: this.profileConfig?.signed_up_adverts_button === true,
						},
					],
				},
				{
					icon: 'fa-regular fa-file',
					title: this.translate('profile-sidebar-documents'),
					isItOpenByDefault: this.isItOpenByDefault,
					isEnabled: !this.profileConfig?.birthday_function_hide,
					children: [
						{
							title: this.translate('profile-sidebar-documents'),
							link: this.pathTranslations[this.language]['profile-documents'],
							isEnabled: true,
						},
					],
				},
			];
		},
	},
});
</script>

<style scoped>
</style>

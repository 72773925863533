<template>
	<div>
		<div class="d-flex align-items-center collapse-item__header" @click="this.isOpened=!this.isOpened">
			<i :class="this.options.icon"></i>
			<h5 class="ms-3">{{ this.options.title }}</h5>
			<template v-if="this.isScreenUnderLg">
				<i v-if="this.options?.children?.length > 0 && this.isOpened" class="fa-solid fa-chevron-down ms-2"></i>
				<i v-if="this.options?.children?.length > 0 && !this.isOpened" class="fa-solid fa-chevron-right ms-2"></i>
			</template>
			<template v-else>
				<i v-if="this.options?.children?.length > 0 && this.isOpened" class="fa-solid fa-chevron-down ms-auto collapse-item__toggler"></i>
				<i v-if="this.options?.children?.length > 0 && !this.isOpened" class="fa-solid fa-chevron-up ms-auto collapse-item__toggler"></i>
			</template>
		</div>
		<Transition name="expand">
			<div v-if="this.isOpened" class="d-flex flex-column">
				<template v-for="(child, index) in this.options.children">
					<router-link v-if="child.isEnabled" :to="{path: child.link}" :class="{'collapse-item__child': true, 'mt-2 mt-lg-0': index === 0}">
						{{ child.title }}
					</router-link>
				</template>
			</div>
		</Transition>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {CollapseItemInterface} from "./Interfaces/CollapseItemInterface";
import ScreenWidthChecker from "../Mixins/ScreenWidthChecker";
import ProfilePageMixin from "../Mixins/ProfilePageMixin";

export default defineComponent({
	name: "CollapseItem",
	mixins: [ScreenWidthChecker, ProfilePageMixin],
	props: {
		options: {
			type: Object as PropType<CollapseItemInterface>,
			required: true,
		},
		textColor: {
			type: String,
			default: '',
			required: false,
		},
	},
	computed: {
		textColorVar(): string {
			if (this.textColor?.length) {
				return `var(--${this.textColor})`;
			} else {
				return '';
			}
		},
	},
	watch: {
		"$route.name"() {
			this.isOpened = false;
		}
	},
	data() {
		return {
			isOpened: this.options.isItOpenByDefault,
		}
	},
});
</script>

<style scoped>
.collapse-item__header {
	color: v-bind(textColorVar);
}

.collapse-item__child {
	color: v-bind(textColorVar);
}

.collapse-item__child.router-link-active.router-link-exact-active {
	color: v-bind(textColorVar);
}

@media (min-width: 992px) {
	.collapse-item__toggler {
		color: v-bind(primaryColorVar);
	}
}
</style>

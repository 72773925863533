import {defineComponent} from "vue";

export default defineComponent({
		computed: {
			// @ts-ignore
			languages() {
				// @ts-ignore
				return this.$root.languagesStore.languageList;
			},
			// @ts-ignore
			translateList() {
				// @ts-ignore
				return this.$root.languagesStore.translateList;
			},
			pathTranslations() {
				let defaultRouteNames = {
					'profile': 'profile',
					'profile-personal': 'personal',
					'profile-notifications': 'notifications',
					'profile-password': 'password',
					'profile-student-data': 'student-data',
					'profile-student-cooperative': 'student-cooperative',
					'profile-education': 'education',
					'profile-biography': 'biography',
					'profile-saved-adverts': 'saved-adverts',
					'profile-saved-searches': 'saved-searches',
					'profile-signed-up-adverts': 'signed-up-adverts',
					'profile-documents': 'documents',
					'advert-detail': '/allas/:slug/:advertid',
				}
				return {
					en: Object.assign(defaultRouteNames, {'advert-detail': '/job/:slug/:advertid'}),
					hu: Object.assign(defaultRouteNames, {'advert-detail': '/allas/:slug/:advertid'}),
					sr: {
						'profile': 'profil',
						'profile-personal': 'licni',
						'profile-notifications': 'obavestenja',
						'profile-password': 'lozinka',
						'profile-student-data': 'student-data',
						'profile-student-cooperative': 'student-cooperative',
						'profile-education': 'education',
						'profile-biography': 'biografija',
						'profile-saved-adverts': 'sacuvani-oglasi',
						'profile-saved-searches': 'sacuvane-pretrage',
						'profile-signed-up-adverts': 'prijavljeni-oglasi',
						'profile-documents': 'documents',
						'advert-detail': '/posao/:slug/:advertid',
					}
				}
			}
		},
		methods: {
			// @ts-ignore
			translate(key: string, params?: { [propName: string]: any }): string {
				if (params && Object.keys(params)?.length > 0) {
					let result = this.translateList[key] ?? key;
					if (result !== key) {
						const keys = Object.keys(params);
						keys.forEach(translateKey => {
							if (result.includes(translateKey)) {
								result = result.replace('{' + translateKey + '}', params[translateKey]);
							}
						});
					}
					return result;
				}
				// @ts-ignore
				return this.translateList[key] ?? key;
			},
			getLang(): string {
				// @ts-ignore
				const currentLang = this.$root.languagesStore.currentLang;
				// @ts-ignore
				const defaultLang = this.$root.languagesStore.defaultLang;
				if (this.pathTranslations.hasOwnProperty(currentLang)) {
					return currentLang;
				}
				return defaultLang;
			},
			advertLocalizeLink(slug: string, advertId: string) {
				const url = this.pathTranslations[this.getLang()]['advert-detail'];
				console.log(url, this.getLang());
				return url.replace(':slug', slug).replace(':advertid', advertId);
			}
		},
	}
);

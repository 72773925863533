<template>
	<v-modal :name="this.modalId" :header="translate('verified-title')">
		<template #body>
			<div class="mb-4">
				<p v-if="this.success" class="mt-4">
					{{ translate('verified-success-text') }}
				</p>
				<div class="mb-4">
					<password-unit
							v-model="this.model.password.value"
							:options="{
								slug: 'register-verify-password',
								name: translate('password'),
								placeholder: translate('min-8-char'),
								minlength: 8,
								required: true,
								infoText: this.model.password.errorText,
								errorState: this.model.password.invalid,
							}"
					/>
				</div>
				<email-verified-input
						v-model="this.model.code.value"
						:email="this.currentSDK?.personal?.email ?? this.email"
						:options="{
							slug: 'register-verify-code',
							name: translate('profile-email-change-modal-verify'),
							standardText: true,
							placeholder: '______',
							minlength: 6,
							required: true,
							infoText: this.model.code.errorText,
							errorState: this.model.code.invalid,
						}"
				/>
			</div>

			<div class="d-flex justify-content-center">
				<button-unit
						:options="{
							text: translate('verified-title'),
							shape: ButtonUnitShape.ROUNDED,
							bgColor: this.primaryColorVar,
							textColor: this.buttonTextColor,
							class: 'lg w-50',
						}"
						@click-on-btn="this.confirm()"
				/>
			</div>
		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import axios from "axios";
import InputErrorHandler from "../../../Mixins/InputErrorHandler";
import SweetAlertMixin from "../../../Mixins/SweetAlertMixin";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import VModalMixin from "../../../Mixins/VModalMixin";
import VModal from "../../VModal.vue";
import PasswordUnit from "../../Units/PasswordUnit.vue";
import EmailVerifiedInput from "../../Units/EmailVerifiedInput.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import {UserSDKInterface} from "../../../Interfaces/UserSDKInterface";

export default defineComponent({
	name: "RegisterVerifyModal",
	components: {ButtonUnit, PasswordUnit, EmailVerifiedInput, VModal},
	mixins: [ProfilePageMixin, InputErrorHandler, SweetAlertMixin, VModalMixin],
	inject: ['emitter'],
	setup() {
		return {
			authStore: useAuthStore(),
		}
	},
	computed: {
		currentSDK(): UserSDKInterface {
			return this.authStore.currentSDK;
		},
		isLoggedIn(): boolean {
			return this.authStore.isLoggedIn;
		}
	},
	data() {
		return {
			modalId: 'register-verify-modal',
			isLoading: false,
			email: '',
			model: {
				code: {
					value: '',
					invalid: false,
					errorText: '',
				},
				password: {
					value: '',
					invalid: false,
					errorText: '',
				}
			} as { [prop: string]: any },
			success: false,
			formKeys: [
				'register-verify-password',
				'register-verify-code',
			],
		}
	},
	mounted() {
		this.email = window.sessionStorage.getItem('email') ?? '';
		this.emitter.on('onModalClose_' + this.modalId, () => {
			this.authStore.logout().then(() => {
				window.location.href = '/';
			});
		});
	},
	methods: {
		confirm(): void {
			this.model.password.invalid = false;
			this.model.code.invalid = false;

			if (this.hasErrors()) {
				return;
			}

			this.isLoading = true;
			axios.post('/api/users/activation', {
				code: this.model.code.value,
				password: this.model.password.value,
				email: this.currentSDK?.personal?.email ?? this.email,
			}).then(response => {
				if (response.status === 200 && response.data.success === true) {
					window.sessionStorage.removeItem('email');
					if (!this.isLoggedIn) {
						this.customSuccessAlert(this.translate('activation-successfully-title'), this.translate('activation-successfully-text'));
						setTimeout(() => {
							this.closeModal();
						}, 1000);
					}
				}
			}).catch(error => {
				if (error.response.status === 422) {
					const errors = Object.keys(error.response.data.errors);
					errors.forEach(element => {
						this.model[element].invalid = true;
						this.model[element].errorText = error.response.data.errors[element][0];
					});
				} else {
					this.errorSaveAlert();
				}
			}).finally(() => this.isLoading = false);
		},
	},
});
</script>

<style scoped>
</style>

import axios from 'axios';
import SweetAlert from "sweetalert2";
import Cookie from "js-cookie";
import router from "./router.ts";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// Admin website secure
if (Cookie.get('p_jwt_session')) {
	window.axios.defaults.headers.common["Authorization"] = 'Bearer ' + atob(Cookie.get('p_jwt_session'));
}

sessionStorage.setItem('requestNumber', 0);

let backupTimer = null;
// Axios Interceptors Request
window.axios.interceptors.request.use(function (request) {
	let sessionRequest = sessionStorage.getItem('requestNumber') ?? '0';
	let requestN = parseInt(sessionRequest) + 1;
	sessionStorage.setItem('requestNumber', requestN);

	if (request.url.search('datasource/school') === -1) {
		try {
			document.querySelector('#v-loader-component').classList.add('show');
		} catch (error) {
		}
	}
	if (request.url.search('datasource/school') === -1 && window.location.href.search('profile') > -1) {
		document.body.style.overflow = 'hidden';
	}
	return request;
});
// Axios Interceptors Response
window.axios.interceptors.response.use(function (response) {
	backupTimer = null;
	let requestN = parseInt(sessionStorage.getItem('requestNumber')) - 1;
	sessionStorage.setItem('requestNumber', requestN);

	if (parseInt(sessionStorage.getItem('requestNumber')) <= 0) {
		let loaderElement = document.querySelector('#v-loader-component');
		if (loaderElement) {
			loaderElement.classList.remove('show');
		}
		if (window.location.href.search('profile') > -1) {
			document.body.style.overflow = 'auto';
		}
		sessionStorage.setItem('requestNumber', 0);
	}
	backupTimer = setTimeout(() => {

		sessionStorage.setItem('requestNumber', 0);
		let loaderElement = document.querySelector('#v-loader-component');
		if (loaderElement) {
			loaderElement.classList.remove('show');
		}
		if (window.location.href.search('profile') > -1) {
			document.body.style.overflow = 'auto';
		}

	}, 15 * 2000);
	return response;
}, function (error) {

	let requestN = parseInt(sessionStorage.getItem('requestNumber')) - 1;
	sessionStorage.setItem('requestNumber', requestN);

	if (parseInt(sessionStorage.getItem('requestNumber')) <= 0) {
		let loaderElement = document.querySelector('#v-loader-component');
		if (loaderElement) {
			loaderElement.classList.remove('show');
		}
		if (window.location.href.search('profile') > -1) {
			document.body.style.overflow = 'auto';
		}
	}

	switch (error.response.status) {
		case 403:
			SweetAlert.fire('', 'Error!', 'error');
			router.push({name: 'dashboard'});
			break;
		case 401:
			Cookie.remove('p_jwt_session');
			location.href = '/';
			break;
	}
	return Promise.reject(error)
});

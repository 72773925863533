<template>
	<v-modal :name="modalId" :header="modalTitle" class="login-registration__modal" :max-width="'600px'">
		<template #body>

			<template v-if="currentSite.simple_login === true">
				<simple-login 
				v-if="currentStep === 'email'"
				@showLostPassword="showLostPasswordModal()"
				@closeModal="closeModal()"
			/>
			</template>
			<template v-else>
				<login-registration-stepper :steps="steps" v-if="showStepper"/>

				<login-registration-email
						v-if="currentStep === 'email'"
						v-model="model"
						:isLoading="isLoading"
						@checkEmail="checkEmail()"
						@closeModal="closeModal()"
				/>

				<login-registration-password
						v-if="currentStep === 'password'"
						v-model="model"
						@showLostPassword="showLostPasswordModal()"
						@goBack="goBack('email')"
						@closeModal="closeModal()"
				/>

				<login-registration-form
						v-if="currentStep === 'form'"
						v-model="model"
						@goBack="goBack('email')"
						@closeModal="closeModal()"
				/>
			</template>

			<login-registration-lost-password
				v-if="currentStep === 'lost-password'"
				v-model="model"
				@goBack="goBack('email')"
				@closeModal="closeModal()"
			/>

		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import axios from "axios";
import {RegistrationModelClass} from "../../../Interfaces/RegistrationModelInterface";
import VModalMixin from "../../../Mixins/VModalMixin";
import VModal from "../../VModal.vue";
import LoginRegistrationStepper from "./LoginRegistrationStepper.vue";
import LoginRegistrationEmail from "./LoginRegistrationEmail.vue";
import LoginRegistrationPassword from "./LoginRegistrationPassword.vue";
import LoginRegistrationLostPassword from "./LoginRegistrationLostPassword.vue";
import LoginRegistrationForm from "./LoginRegistrationForm.vue";
import SimpleLogin from "./SimpleLogin.vue";
import { useSiteCheckStore } from "../../../Stores/SiteCheck/SiteCheck";
import { SiteCheckInterface } from "../../../Stores/SiteCheck/Interfaces/SiteCheckInterface";

export default defineComponent({
	name: "LoginRegistrationModal",
	components: {LoginRegistrationForm, LoginRegistrationLostPassword, LoginRegistrationPassword, LoginRegistrationEmail, LoginRegistrationStepper, VModal, SimpleLogin},
	mixins: [VModalMixin],
	inject: ['emitter'],
	setup() {
		return {
			siteStore: useSiteCheckStore()
		}
	},
	data() {
		return {
			modalId: 'login-registration-modal',
			isLoading: false,
			steps: [
				{
					title: 'email',
					active: true,
					done: false,
				},
				{
					title: 'personal-data',
					active: false,
					done: false,
				},
			],
			currentStep: 'email' as 'email' | 'password' | 'lost-password' | 'form',
			model: new RegistrationModelClass(),
		}
	},
	mounted() {
		this.preloadSocialLiteData();
	},
	computed: {
		showStepper(): boolean {
			return this.currentStep !== 'activation' && this.currentStep !== 'activation-or-apply' && this.currentStep !== 'lost-password';
		},
		modalTitle(): string {
			return this.currentStep === 'lost-password' ? this.translate('new-password-title') : this.translate('login-or-registration');
		},
		currentSite(): SiteCheckInterface {
			return this.siteStore.currentSite;
		},
	},
	methods: {
		reset(): void {
			this.steps = [
				{
					title: 'email',
					active: true,
					done: false,
				},
				{
					title: 'personal-data',
					active: false,
					done: false,
				},
			];
			this.currentStep = 'email';
			this.model = new RegistrationModelClass();
		},
		checkEmail(): void {
			this.isLoading = true;
			window.sessionStorage.setItem('email', this.model.email);
			axios.post('/api/check-email', {email: this.model.email}).then(response => {
				if (response.status === 200 && response.data.success && response.data.status === 'active') {
					this.steps[0].active = false;
					this.steps[0].done = true;
					this.steps[1].active = true;
					this.currentStep = 'password';
				}
				if (response.status === 200 && response.data.success && response.data.status === 'inactive') {
					this.closeModal();
					this.openRegisterVerifyModal();
				}
			}).catch((error) => {
				if (error.response.status === 404 && error.response.data.success) {
					this.steps[0].active = false;
					this.steps[0].done = true;
					this.steps[1].active = true;
					this.currentStep = 'form';
				}

			}).finally(() => this.isLoading = false);
		},
		goBack(step: 'email' | 'password' | 'lost-password' | 'form'): void {
			if (step === 'email') {
				this.currentStep = 'email';
				this.steps[0].active = true;
				this.steps[0].done = false;
				this.steps[1].active = false;
			}
		},
		preloadSocialLiteData(): void {
			if (this.$route?.query.hasOwnProperty('email') && this.$route?.query.hasOwnProperty('first_name') && this.$route?.query.hasOwnProperty('last_name')) {
				//@ts-ignore
				this.model.email = this.$route.query.email;
				//@ts-ignore
				this.model.first_name = this.$route.query.first_name;
				//@ts-ignore
				this.model.last_name = this.$route.query.last_name;
			}
		},
		openRegisterVerifyModal(): void {
			this.emitter.emit('onModalShow_register-verify-modal');
		},
		showLostPasswordModal() {
			if(this.currentSite.simple_login === true) {
				const email = document.getElementById('login-email');
				if(!email.checkValidity()) {
					email.reportValidity();
					return;
				}
				this.model.email = email.value;
			}
			this.currentStep = 'lost-password';
		}
	},
});
</script>

<style scoped>
</style>
